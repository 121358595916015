import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  twoQualificationWrapper: {
    display: 'flex',
    boxShadow:
      '0px 2px 9px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: '2rem',
    padding: '1rem'
  },

  existingQualificationWrapper: {
    display: 'flex',
    flex: '2',
    flexDirection: 'column'
  },

  pendingQualificationWrapper: {
    display: 'flex',
    flex: '2',
    marginLeft: '1rem',
    flexDirection: 'column'
  },
  qualificationActionWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center'
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10%',
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  qualificationP: {
    fontSize: '14px',
    marginBottom: '0.5rem',
    textAlign: 'left'
  },
  oneQualificationWrapper: {
    marginBottom: '2rem'
  },
  verticalLine: {
    height: '500px',
    borderLeft: '1px solid rgb(223 227 245)',
    marginLeft: '10px'
  },
  imgContainer: {
    height: '300px',
    width: '100%',
    backgroundColor: '#E7E9EB'
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  }
}));

const QualificationList = ({ qualificationItem, setQualificationRecord, employeeId }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [editQualificationItem, setEditedQualificationItem] = useState({
    employeeKeyPayId: qualificationItem[1]?.employeeKeyPayId,
    pendingQualificationId: qualificationItem[1]?._id,
    issueDate: qualificationItem[1]?.issueDate ? format(new Date(qualificationItem[1]?.issueDate), 'yyyy-MM-dd') : '',
    expiryDate: qualificationItem[1]?.expiryDate
      ? format(new Date(qualificationItem[1]?.expiryDate), 'yyyy-MM-dd')
      : '',
    referenceNumber: qualificationItem[1]?.referenceNumber || '',
    qualificationImage: qualificationItem[1]?.qualificationUrl || ''
  });

  const handleAcceptQualification = async (e, qId) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const dbQualification = await employeeQualificationService.getQualificationFromDb(qId);
      const dbQualificationStatus = dbQualification?.data?.data?.status;

      if (dbQualificationStatus !== 'pending') {
        toast.success(`The qualification was already ${dbQualificationStatus}.`);
        return;
      }

      const response = await employeeQualificationService.createEmployeeQualification(qId);

      const employeeUpdatedQualifications = await employeeQualificationService.getEmployeeUpdatedQualifications(
        employeeId
      );
      const employeeQualificationRecords = employeeUpdatedQualifications?.data?.data;

      setQualificationRecord(employeeQualificationRecords);

      toast.success(response?.data?.message || 'Qualification accepted successfully.');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error accepting qualification.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectQualification = async (e, qId) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const dbQualification = await employeeQualificationService.getQualificationFromDb(qId);
      const dbQualificationStatus = dbQualification?.data?.data?.status;

      if (dbQualificationStatus !== 'pending') {
        toast.success(`The qualification was already ${dbQualificationStatus}.`);
        return;
      }

      // eslint-disable-next-line no-undef
      const rejectReason = prompt('Reason to reject the qualification', '');

      if (rejectReason !== null && rejectReason !== '') {
        const response = await employeeQualificationService.changeEmployeeQualificationStatus(qId, rejectReason);

        const employeeUpdatedQualifications = await employeeQualificationService.getEmployeeUpdatedQualifications(
          employeeId
        );
        const employeeQualificationRecords = employeeUpdatedQualifications?.data?.data;

        setQualificationRecord(employeeQualificationRecords);

        toast.success(response?.data?.message || 'Qualification rejected successfully.');
      } else {
        return toast.error('Please write a reason to reject the qualification');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error rejecting qualification.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmployeeQualificationEdit = async (e, qId) => {
    e.preventDefault();
    setEditClicked(true);
    setEditedQualificationItem({ ...editQualificationItem, pendingQualificationId: qId });
  };

  const handleEditQualification = (e) => {
    const updateValue = e.target.value;
    setEditedQualificationItem({ ...editQualificationItem, [e.target.name]: updateValue });
  };

  const handleEditQualificationFile = (e) => {
    const updateFile = e.target.files[0];
    setEditedQualificationItem({ ...editQualificationItem, [e.target.name]: updateFile });
  };

  const handleEmployeeUpdate = () => {
    const qualificationData = {
      employeeKeyPayId: editQualificationItem?.employeeKeyPayId,
      name: editQualificationItem?.name,
      pendingQualificationId: editQualificationItem?.pendingQualificationId,
      issueDate: editQualificationItem?.issueDate,
      expiryDate: editQualificationItem?.expiryDate,
      referenceNumber: editQualificationItem?.referenceNumber
    };

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('qualificationData', JSON.stringify(qualificationData));

    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    // eslint-disable-next-line no-undef
    if (editQualificationItem?.qualificationImage instanceof File) {
      formData.append('avatar', editQualificationItem?.qualificationImage);
    }

    employeeQualificationService
      .updateEmployeeQualificationByAdmin(formData)
      .then((data) => {
        if (data?.data?.data?.length) {
          setQualificationRecord(data?.data?.data);
        }
        toast.success(data?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message?.message || 'Qualification has not updated');
      })
      .finally(() => {
        setEditClicked(false);
      });
  };

  return (
    <div>
      <h3>
        Qualification update by {qualificationItem[0]?.employeeDisplayName || qualificationItem[1]?.employeeDisplayName}
      </h3>
      {(qualificationItem.length > 0 && qualificationItem[0] !== null) ||
      (qualificationItem.length > 0 && qualificationItem[0] === null && qualificationItem[1]?.status === 'pending') ? (
        <div className={classes.twoQualificationWrapper}>
          <div className={classes.existingQualificationWrapper}>
            <h3>Existing {qualificationItem[0]?.name || qualificationItem[1]?.name}</h3>
            <div>
              <div style={{ textAlign: 'left' }}>
                <p className={classes.qualificationP}>Qualification Name: {qualificationItem[0]?.name}</p>
                <p className={classes.qualificationP}>Qualification Issue Date: {qualificationItem[0]?.issueDate}</p>
                <p className={classes.qualificationP}>Qualification Expiry Date: {qualificationItem[0]?.expiryDate}</p>
                <p className={classes.qualificationP}>
                  Qualification Card Number: {qualificationItem[0]?.referenceNumber}
                </p>
              </div>

              <div style={{ textAlign: 'left' }}>
                <p className={classes.qualificationP}>Qualification Image:</p>
                <div className={classes.imgContainer}>
                  <img src={qualificationItem[0]?.qualificationUrl} alt="qualification_image" className={classes.img} />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.verticalLine}></div>

          <div className={classes.pendingQualificationWrapper}>
            <h3>Updated {qualificationItem[1]?.name}</h3>
            <div>
              <p className={classes.qualificationP}>Qualification Name: {qualificationItem[1]?.name}</p>
              {editClicked && qualificationItem[1]?.issueDate ? (
                <div style={{ marginBottom: '1rem' }}>
                  <span className={classes.qualificationP}> Qualification Issue Date:</span>

                  <input
                    type="date"
                    name="issueDate"
                    value={format(new Date(editQualificationItem?.issueDate), 'yyyy-MM-dd')}
                    onChange={handleEditQualification}
                  />
                </div>
              ) : (
                <p className={classes.qualificationP}>Qualification Issue Date: {qualificationItem[1]?.issueDate}</p>
              )}
              {editClicked && qualificationItem[1]?.expiryDate ? (
                <div style={{ marginBottom: '1rem' }}>
                  <span className={classes.qualificationP}>Qualification Expiry Date:</span>
                  <input
                    type="date"
                    name="expiryDate"
                    value={format(new Date(editQualificationItem?.expiryDate), 'yyyy-MM-dd')}
                    onChange={handleEditQualification}
                  />
                </div>
              ) : !editClicked ? (
                <p className={classes.qualificationP}>Qualification Expiry Date: {qualificationItem[1]?.expiryDate}</p>
              ) : (
                ''
              )}
              {editClicked && qualificationItem[1]?.referenceNumber ? (
                <div style={{ marginBottom: '1rem' }}>
                  <span className={classes.qualificationP}>Qualification Card Number:</span>
                  <input
                    placeholder="type"
                    name="referenceNumber"
                    value={editQualificationItem.referenceNumber}
                    onChange={handleEditQualification}
                  />
                </div>
              ) : (
                <p className={classes.qualificationP}>
                  Qualification Card Number: {qualificationItem[1]?.referenceNumber}
                </p>
              )}
              <div>
                {!editClicked && <p>Qualification Image:</p>}
                {editClicked && qualificationItem[1]?.referenceNumber ? (
                  <div style={{ marginBottom: '1rem' }}>
                    Qualification Image:
                    <input
                      type="file"
                      id="qualificationImage"
                      name="qualificationImage"
                      accept="image/*"
                      onChange={handleEditQualificationFile}
                    />
                  </div>
                ) : (
                  <div className={classes.imgContainer}>
                    <img
                      src={qualificationItem[1]?.qualificationUrl}
                      alt="qualification_image"
                      className={classes.img}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={classes.verticalLine}></div>

          <div className={classes.qualificationActionWrapper}>
            <h3>Actions</h3>
            <div style={{ marginTop: '56%' }}>
              {editClicked ? (
                <div style={{ display: 'flex', paddingLeft: '1rem' }}>
                  <Button
                    onClick={() => setEditClicked(false)}
                    variant="outlined"
                    color="primary"
                    style={{
                      marginRight: '1rem'
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleEmployeeUpdate}
                    variant="outlined"
                    color="secondary"
                    style={{
                      marginRight: '1rem'
                    }}
                    disabled={isLoading}
                  >
                    Update
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={(e) => handleEmployeeQualificationEdit(e, qualificationItem[1]?._id)}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  style={{
                    marginRight: '1rem'
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
            {!editClicked && (
              <div className={classes.actionWrapper}>
                <Button
                  onClick={(e) => handleRejectQualification(e, qualificationItem[1]?._id)}
                  variant="outlined"
                  color="secondary"
                  style={{
                    marginRight: '1rem',
                    marginLeft: '1rem'
                  }}
                  disabled={isLoading}
                >
                  Reject
                </Button>
                <Button
                  onClick={(e) => handleAcceptQualification(e, qualificationItem[1]?._id)}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Accept
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.oneQualificationWrapper}>
          <h3 style={{ textAlign: 'center' }}> {qualificationItem[1]?.name}</h3>
          <div
            style={{
              display: 'flex',
              boxShadow:
                '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p className={classes.qualificationP}>Qualification Name: {qualificationItem[1]?.name}</p>
              <p className={classes.qualificationP}>Qualification Issue Date: {qualificationItem[1]?.issueDate}</p>
              <p className={classes.qualificationP}>
                Qualification Expiry Date: {qualificationItem[1]?.expiryDate || ''}
              </p>
              <p className={classes.qualificationP}>
                Qualification Card Number: {qualificationItem[1]?.referenceNumber}
              </p>
            </div>
            <div style={{ display: 'flex', flex: '1' }}>
              {/* <p className={classes.qualificationP}>Qualification Image:</p> */}
              <div className={classes.imgContainer}>
                <img src={qualificationItem[1]?.qualificationUrl} alt="qualification_image" className={classes.img} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QualificationList;

QualificationList.propTypes = {
  qualificationItem: PropTypes.any,
  setQualificationRecord: PropTypes.any,
  employeeId: PropTypes.any
};
