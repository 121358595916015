import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardActionArea,
  CardActions,
  Button,
  CardContent,
  Typography,
  useMediaQuery,
  CardMedia,
  TextField
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import employeeQualificationService from '../../../../services/EmployeeQualificationService';
import toast from 'react-hot-toast';
import { formatEmployeeQualifications } from '../../helper/helper';
import QualificationList from '../../../admin/AdminQualification/QualificationList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  cardDiv: {
    display: 'flex'
  },
  cardActionArea: {
    display: 'flex',
    flex: 4
  },
  cardActions: {
    display: 'flex',
    flex: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  typography: {
    marginBottom: '10px'
  }
}));

const UserQualification = ({ row, onCancelChanges }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [qboQualificationRecord, setQboQualificationRecord] = useState([]);
  const [pendingEmployeeQualifications, setPendingEmployeeQualifications] = useState([]);
  const [editClickedId, setEditClickedId] = useState(null);
  const [editClicked, setEditClicked] = useState(false);
  const [editQualificationObject, setEditQualificationObject] = useState();
  const [qualificationFile, setQualificationFile] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const [loading, setLoading] = useState(false);

  const [qualificationChange, setQualificationChange] = useState({
    employeeKeyPayId: row?.KeyPayEmployeeId,
    pendingQualificationId: '',
    qualificationName: '',
    issueDate: new Date(),
    expiryDate: new Date(),
    referenceNumber: '',
    qualificationFile: '',
    documentId: ''
  });

  //to list the qualifications from qbo and get employee qualifications
  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        const qboQualificationResponse = await employeeQualificationService.getQboQualification();
        const qboQualificationData = qboQualificationResponse?.data?.data;

        const keyPayEmployeeId = row?.KeyPayEmployeeId;

        const employeeQualifications = await employeeQualificationService.getEmployeeQboQualifications(
          keyPayEmployeeId
        );
        const employeeQualificationRecords = employeeQualifications?.data?.data;

        const formattedRecords = formatEmployeeQualifications(employeeQualificationRecords);

        const updateQboQualificationRecord = qboQualificationData?.map((qboRecord) => {
          const matchRecord = formattedRecords.find(
            (employeeRecord) => qboRecord.id === employeeRecord.qualificationId
          );
          if (matchRecord) {
            return { ...qboRecord, ...matchRecord };
          }
          return qboRecord;
        });
        setQboQualificationRecord(updateQboQualificationRecord);
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get qbo qualification');
      }
    };
    fetchEmployeeQualificationById();
  }, [row?.KeyPayEmployeeId]);

  //check if employee has modified qualifications: check in db for : employee.employeeKeyPayId, if any status is "pending"
  useEffect(() => {
    const fetchEmployeePendingQualifications = async () => {
      const response = await employeeQualificationService.getEmployeeUpdatedQualifications(row?.id);
      const pendingQualifications = response?.data?.data;

      setPendingEmployeeQualifications(pendingQualifications);
      setEmployeeId(row?.id);
    };

    fetchEmployeePendingQualifications();
  }, [row?.id]);

  useEffect(() => {
    setQualificationChange((prev) => ({
      ...prev,
      pendingQualificationId: editClickedId,
      qualificationName: editQualificationObject?.name,
      issueDate: editQualificationObject?.issueDate
        ? format(new Date(editQualificationObject?.issueDate), 'yyyy-MM-dd')
        : '',
      expiryDate: editQualificationObject?.expiryDate
        ? format(new Date(editQualificationObject?.expiryDate), 'yyyy-MM-dd')
        : '',
      referenceNumber: editQualificationObject?.cardNumber || '',
      qualificationFile: editQualificationObject?.fileContent,
      documentId: editQualificationObject?.documentId
    }));
  }, [editClickedId, editQualificationObject]);

  const handleQualificationEdit = (qualificationId, qualification) => {
    setEditClickedId(qualificationId);
    setEditClicked(true);
    setEditQualificationObject(qualification);
  };

  const handleQualificationSave = () => {
    setLoading(true);

    const qualificationData = {
      employeeKeyPayId: qualificationChange?.employeeKeyPayId,
      qualificationId: qualificationChange?.pendingQualificationId,
      name: qualificationChange?.qualificationName,
      expiryDate: qualificationChange?.expiryDate,
      referenceNumber: qualificationChange?.referenceNumber,
      issueDate: qualificationChange?.issueDate,
      documentId: qualificationChange?.documentId
    };

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('qualificationData', JSON.stringify(qualificationData));

    if (qualificationFile) {
      formData.append('file', qualificationFile);
    }

    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    employeeQualificationService
      .updateEmployeeQualificationInQboByAdmin(formData)
      .then((data) => {
        setEditClickedId(null);
        setEditClicked(false);

        const formattedRecords = formatEmployeeQualifications([data?.data?.data]);

        const updateQboQualificationRecord = qboQualificationRecord?.map((qboRecord) => {
          const matchRecord = formattedRecords.find(
            (employeeRecord) => qboRecord.id === employeeRecord.qualificationId
          );
          if (matchRecord) {
            return { ...qboRecord, ...matchRecord };
          }
          return qboRecord;
        });
        setQboQualificationRecord(updateQboQualificationRecord);

        toast.success(data?.data?.message || `Successfully updated ${qualificationData?.name}`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || `Error while updating qualification ${qualificationData?.name}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleQualificationChange = (e) => {
    setQualificationChange({ ...qualificationChange, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setQualificationFile(e.target.files[0]);
  };

  return (
    <div style={{ height: '800px' }}>
      <h2 style={{ borderBottom: 'none', padding: isSmall ? '10px 1rem' : '10px 2rem', overflowX: 'hidden' }}>
        {pendingEmployeeQualifications.length > 0 ? '' : 'Qualifications'}
      </h2>

      {pendingEmployeeQualifications.length > 0 ? (
        pendingEmployeeQualifications?.map((qualificationItem) => {
          return (
            <QualificationList
              qualificationItem={qualificationItem}
              key={qualificationItem[0]?.qualificationId || qualificationItem[1]?.qualificationId}
              setQualificationRecord={setPendingEmployeeQualifications}
              employeeId={employeeId}
            />
          );
        })
      ) : (
        <div style={{ padding: isSmall ? '10px 1rem' : '10px 2rem' }}>
          {qboQualificationRecord.length > 0 &&
            qboQualificationRecord.map((qualification) => {
              return (
                <Card key={qualification.id} style={{ marginBottom: '1rem', padding: '1rem' }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {qualification.name}
                  </Typography>
                  <div className={classes.cardDiv}>
                    <CardActionArea className={classes.cardActionArea}>
                      {editClickedId === qualification.qualificationId && qualification.cardNumber ? (
                        <div>
                          {qualificationFile && (
                            <img
                              // eslint-disable-next-line no-undef
                              src={URL.createObjectURL(qualificationFile)}
                              name="qualificationFile"
                              alt="Qualification file"
                              style={{ height: '140px' }}
                            />
                          )}
                          <TextField
                            size="small"
                            name="qualificationFile"
                            fullWidth
                            variant="outlined"
                            onChange={handleFileChange}
                            type="file"
                            style={{ marginTop: '15px' }}
                          />
                        </div>
                      ) : (
                        <CardMedia
                          component="img"
                          alt="Qualification Image"
                          height="140"
                          image={qualification?.fileContent}
                          title="Qualification Image"
                          style={{ display: 'flex', flex: '2' }}
                        />
                      )}

                      <CardContent style={{ display: 'flex', flex: '3', flexDirection: 'column', gap: 4 }}>
                        <div style={{ marginRight: '10px' }}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className={classes.typography}
                          >
                            Card Number:
                          </Typography>
                          {editClickedId === qualification.qualificationId && qualification.cardNumber ? (
                            <TextField
                              size="small"
                              name="referenceNumber"
                              fullWidth
                              variant="outlined"
                              value={qualificationChange.referenceNumber}
                              onChange={handleQualificationChange}
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              className={classes.typography}
                            >
                              <b>{qualification?.cardNumber}</b>
                            </Typography>
                          )}
                        </div>

                        <div style={{ marginRight: '10px' }}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className={classes.typography}
                          >
                            Issue Date:
                          </Typography>
                          {editClickedId === qualification?.qualificationId && qualification?.issueDate ? (
                            <TextField
                              name="issueDate"
                              type="date"
                              fullWidth
                              variant="outlined"
                              value={qualificationChange.issueDate}
                              onChange={handleQualificationChange}
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary" component="p">
                              <b>{qualification?.issueDate}</b>
                            </Typography>
                          )}
                        </div>

                        <div>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className={classes.typography}
                          >
                            Expiry Date:
                          </Typography>
                          {editClickedId === qualification?.qualificationId && qualification?.expiryDate ? (
                            <TextField
                              name="expiryDate"
                              type="date"
                              fullWidth
                              variant="outlined"
                              value={qualificationChange.expiryDate}
                              onChange={handleQualificationChange}
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary" component="p">
                              <b>{qualification.expiryDate}</b>
                            </Typography>
                          )}
                        </div>
                      </CardContent>
                    </CardActionArea>
                    <CardActions className={classes.cardActions}>
                      {editClicked && editClickedId === qualification?.qualificationId ? (
                        <div>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                              setEditClickedId(null), setQualificationFile('');
                            }}
                            disabled={loading}
                          >
                            Cancel
                          </Button>
                          <Button size="small" color="primary" onClick={handleQualificationSave} disabled={loading}>
                            Save
                          </Button>
                        </div>
                      ) : (
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => handleQualificationEdit(qualification?.qualificationId, qualification)}
                          disabled={loading}
                        >
                          Edit
                        </Button>
                      )}
                    </CardActions>
                  </div>
                </Card>
              );
            })}
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: isSmall ? '1rem' : '2rem',
          paddingTop: '1rem',
          paddingBottom: '2rem'
        }}
      >
        <Button autoFocus variant="outlined" color="secondary" onClick={onCancelChanges}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default UserQualification;

UserQualification.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func
};
