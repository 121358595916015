/**
 * Format expense data api response
 */
export function getFormattedTableRows(expenseList) {
  return expenseList.map((item) => ({
    id: item._id,
    BillableStatus: new Boolean(item.BillableStatus).toString(),
    EmployeeRef: item.EmployeeRef || '',
    CustomerRef: item.CustomerRef || '',
    ClassRef: item.ClassRef || '',
    ItemRef: item.ItemRef || '',
    AccountRef: item.AccountRef || '',
    ReceiptDate: new Date(item.ReceiptDate) || '',
    Description: item.Description || '',
    variationNote: item.variationNote || '',
    expenseReceiptUrl: item.expenseReceiptImageUrl || '',
    status: item.status || '',
    workType: item.workType || '',
    KeyPayEmployeeId: item.KeyPayEmployeeId || '',
    VendorRef: item.VendorRef || '',
    jobTitle: item.jobTitle || '',
    email: item.email || '',
    paidBy: item.PaidByEmployee ? 'Paid By Employee' : 'Paid By Company',
    Price: item.Price || '',
    Qty: item.Qty || '',
    PurchaseOrder: item.PurchaseOrder || '',
    GlobalTaxCalculation: item.GlobalTaxCalculation || '',
    DocNumber: item.DocNumber || '',
    expenseCreatedDate: new Date(item.createdAt) || ''
  }));
}

export function getFormattedGlobalTaxCalculation() {
  return ['TaxExcluded', 'TaxInclusive'];
}
