import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get all the employee qualification
function getEmployeeQualifications() {
  const endpoint = BASE_URL + 'employeeQualification/getEmployeeQualifications';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get all the qbo qualification
function getQboQualification() {
  const endpoint = BASE_URL + 'employeeQualification/getQboQualification';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//create or update employee qualification
// function createEmployeeQualification(formData) {
//   const endpoint = BASE_URL + 'employeeQualification/createEmployeeQualification';
//   return axios.post(endpoint, formData, {
//     headers: {
//       // eslint-disable-next-line no-undef
//       authorization: `${localStorage.getItem('token')}`,
//       'Content-Type': 'multipart/form-data'
//     }
//   });
// }

//get employee qualification by id
function getEmployeeQualification(qId) {
  const endpoint = BASE_URL + `employeeQualification/getEmployeeQualification/${qId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//send notification to the admin
function notifyAdminEmployeeQualificationUpdate(formData) {
  const endpoint = BASE_URL + 'employeeQualification/notifyAdminEmployeeQualificationUpdate';
  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//change qualification status only in verd db and send confirmation email both employee and admin
function changeEmployeeQualificationStatus(qId, rejectReason) {
  const endpoint = BASE_URL + 'employeeQualification/changeEmployeeQualificationStatus';
  return axios.post(
    endpoint,
    { qId: qId, rejectReason: rejectReason },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//get verd qualification
function getQualificationFromDb(qId) {
  const endpoint = BASE_URL + 'employeeQualification/getQualificationFromDb';
  return axios.post(
    endpoint,
    { qId: qId },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//when admin accept qualification from mail
//create or update employee qualification
function createEmployeeQualification(qId) {
  const endpoint = BASE_URL + 'employeeQualification/createEmployeeQualification';
  return axios.post(
    endpoint,
    { qId: qId },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//get qualification by qualification id
function getEmployeeQualificationById(qualificationPayload) {
  const endpoint = BASE_URL + 'employeeQualification/getEmployeeQualificationById';
  return axios.post(endpoint, qualificationPayload);
}

//get all the updated qualification of employee in AdminQualification, qualification/:employeeId, the data will be like [[{},{}],[{},{}],[{},{}]]
function getEmployeeUpdatedQualifications(employeeId) {
  const endpoint = BASE_URL + `employeeQualification/getEmployeeUpdatedQualifications/${employeeId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get qualification by name :from verd db
function getQualificationByName(qualificationName) {
  const endpoint = BASE_URL + `employeeQualification/getQualificationByName/${qualificationName}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//edit qualification by admin from action : accept/ reject edit/cancel
function updateEmployeeQualificationByAdmin(formData) {
  const endpoint = BASE_URL + 'employeeQualification/updateEmployeeQualificationByAdmin';
  return axios.put(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//fetch employee qbo qualifications by id in admin: users/Qualification tab
function getEmployeeQboQualifications(employeeKeyPayId) {
  const endpoint = BASE_URL + `employeeQualification/getEmployeeQboQualifications/${employeeKeyPayId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//fetch employee pending qualifications from db by keypay id
function getEmployeePendingDbQualifications(employeeKeyPayId) {
  const endpoint = BASE_URL + `employeeQualification/getEmployeePendingDbQualifications/${employeeKeyPayId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//when user's qualification is edited by admin from User->Qualification tab
function updateEmployeeQualificationInQboByAdmin(formData) {
  const endpoint = BASE_URL + 'employeeQualification/updateEmployeeQualificationInQboByAdmin';
  return axios.put(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

const employeeQualificationService = {
  getEmployeeQualifications,
  getQboQualification,
  createEmployeeQualification,
  getEmployeeQualification,
  notifyAdminEmployeeQualificationUpdate,
  changeEmployeeQualificationStatus,
  getQualificationFromDb,
  getEmployeeQualificationById,
  getEmployeeUpdatedQualifications,
  getQualificationByName,
  updateEmployeeQualificationByAdmin,
  getEmployeeQboQualifications,
  getEmployeePendingDbQualifications,
  updateEmployeeQualificationInQboByAdmin
};

export default employeeQualificationService;
