/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from 'react';
import {
  SortingState,
  EditingState,
  PagingState,
  SummaryState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  DataTypeProvider,
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering,
  TableFixedColumns,
  TableSummaryRow,
  TableColumnResizing,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  TableSelection,
  ExportPanel
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Getter, Plugin } from '@devexpress/dx-react-core';

import TableCell from '@mui/material/TableCell';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

import { format } from 'date-fns';
import { TextField, makeStyles } from '@material-ui/core';

import ReactSelect from 'react-select';
import { toast } from 'react-hot-toast';
import { getFormattedBillableStatus, isBillableStatusValid } from '../../../MyTime/table/helpers';
import expenseService from '../../../../services/ExpenseService';
import { getFormattedGlobalTaxCalculation } from '../../../MyExpense/table/helper';
import ImageFullScreenDialog from '../../../../shared/dialog/ImageFullScreenDialog';

const PREFIX = 'Demo';
const classes = {
  lookupEditCell: `${PREFIX}-lookupEditCell`,
  dialog: `${PREFIX}-dialog`,
  inputRoot: `${PREFIX}-inputRoot`,
  selectMenu: `${PREFIX}-selectMenu`
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.lookupEditCell}`]: {
    padding: theme.spacing(1)
  },
  [`& .${classes.dialog}`]: {
    width: 'calc(100% - 16px)'
  },
  [`& .${classes.inputRoot}`]: {
    width: '100%'
  },
  [`& .${classes.selectMenu}`]: {
    position: 'absolute !important'
  }
}));

const useStyles = makeStyles({
  root: {
    '& tfoot': {
      display: 'none'
    }
  }
});

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button color="primary" onClick={onExecute} title="Create new row">
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row" size="large">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Are you sure you want to delete this row?')) {
        onExecute();
      }
    }}
    title="Delete row"
    size="large"
  >
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

const LookupEditCell = ({ availableColumnValues, value, onValueChange, column, row }) => {
  const columnName = column.name;

  const options = availableColumnValues[columnName].map((item) => {
    return {
      value: item.name || item,
      label: item.name || item,
      id: item.value || item
    };
  });

  const dropdownValue = { value, label: value };
  return (
    <>
      <StyledTableCell className={classes.lookupEditCell}>
        <ReactSelect
          className="basic-single"
          classNamePrefix="select"
          defaultValue={dropdownValue}
          isSearchable={true}
          name="color"
          options={options}
          isClearable={true}
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          onChange={(selectedOption) => {
            if (selectedOption === null) {
              // Handle the case when the selected option is cleared
              onValueChange(null); // or handle it based on your requirements
            } else {
              if (
                columnName === 'ClassRef' ||
                columnName === 'CustomerRef' ||
                columnName === 'ItemRef' ||
                columnName === 'AccountRef'
                // columnName === 'VendorRef'
              ) {
                onValueChange({ name: selectedOption.value, value: selectedOption.id });
              } else {
                onValueChange(selectedOption.value);
              }
            }
          }}
        />
      </StyledTableCell>
    </>
  );
};

const Calendar = ({ ...props }) => {
  const { value, onValueChange, column } = props;

  return (
    <td>
      <TextField
        type="date"
        onChange={(event) => onValueChange(event.target.value)}
        name={column.name}
        value={format(new Date(value), 'yyyy-MM-dd')}
      />
    </td>
  );
};

const Cell = (props) => {
  if (props.column.name === 'ReceiptDate') {
    return <Table.Cell {...props} value={format(new Date(props.value), 'dd/MM/yyyy')} />;
  }

  if (props.column.name === 'ExpenseReceiptUrl') {
    return <Table.Cell {...props} value={<img src={props.value} style={{ width: '30px', height: '30px' }} />} />;
  }

  return <Table.Cell {...props} />;
};

const EditCell = ({ classRefs, itemRefs, customerRefs, vendorRefs, creditCardAccount, employeeRefs, ...props }) => {
  const columnName = props.column.name;

  const availableColumnValues = {
    ClassRef: classRefs,
    CustomerRef: customerRefs,
    ItemRef: itemRefs,
    // VendorRef: vendorRefs,
    AccountRef: creditCardAccount,
    EmployeeRef: employeeRefs,
    BillableStatus: getFormattedBillableStatus(),
    GlobalTaxCalculation: getFormattedGlobalTaxCalculation()
  };

  if (
    columnName === 'CustomerRef' ||
    columnName === 'ClassRef' ||
    columnName === 'ItemRef' ||
    columnName === 'AccountRef' ||
    columnName === 'EmployeeRef' ||
    columnName === 'CreditCardAccount' ||
    columnName === 'BillableStatus' ||
    columnName === 'GlobalTaxCalculation'
  ) {
    return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
  }

  if (columnName === 'ReceiptDate') {
    return <Calendar {...props} />;
  }

  return <TableEditRow.Cell {...props} />;
};

const TableHeaderContent = ({ totalEmployeeCount, totalDateCount, column, children, ...restProps }) => {
  return (
    <TableHeaderRow.Content column={column} {...restProps}>
      {column.name === 'EmployeeRef' ? <div style={{ marginRight: '5px' }}>({totalEmployeeCount}) </div> : null}
      {column.name === 'Day' ? <div style={{ marginRight: '5px' }}>({totalDateCount}) </div> : null}
      {children}
    </TableHeaderRow.Content>
  );
};

const EditCommandCell = ({ errors, disableEditAndDelete, ...props }) => {
  const children = props.children;
  const row = props.row;

  // const disableEditAndDelete = row.status !== 'WithEmployee';

  const renderEditAndDelete = (child) => {
    if (disableEditAndDelete(row)) {
      return <>-</>;
    }

    return child;
  };

  return (
    <TableEditColumn.Cell {...props}>
      {React.Children.map(children, (child) =>
        child?.props.id === 'commit'
          ? React.cloneElement(child, { disabled: errors[props.tableRow.rowId] })
          : renderEditAndDelete(child)
      )}
    </TableEditColumn.Cell>
  );
};

class PatchedIntegratedSelection extends React.PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props;

    return (
      <Plugin>
        <Getter
          name="rows"
          computed={({ rows }) => {
            this.rows = rows;
            return rows.filter(rowSelectionEnabled);
          }}
        />
        <IntegratedSelection {...restProps} />
        <Getter name="rows" computed={() => this.rows} />
      </Plugin>
    );
  }
}

class PatchedTableSelection extends React.PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props;

    return (
      <TableSelection
        cellComponent={(props) =>
          rowSelectionEnabled(props.tableRow.row) ? <TableSelection.Cell {...props} /> : <Table.StubCell {...props} />
        }
        {...restProps}
      />
    );
  }
}

//customize description cell before downloading
const customizeCell = (cell, row, column) => {
  if (column.name === 'Description') {
    cell.value =
      row.ReceiptDate.toLocaleDateString() +
      ' ' +
      row.EmployeeRef.name +
      ' ' +
      row.Description +
      ' ' +
      row.variationNote;
  }
  if (column.name === 'ReceiptDate') {
    cell.value = row.ReceiptDate.toLocaleDateString();
  }
};

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};
const DateFormatter = ({ value }) => <span>{format(value, 'dd/MM/yyyy')}</span>;

const DateTypeProvider = (props) => <DataTypeProvider {...props} formatterComponent={DateFormatter} />;

const ImageProvider = (props) => {
  return <DataTypeProvider formatterComponent={ImageFullScreenDialog} {...props} />;
};
const getRowId = (row) => row.id;

const AdminExpenseTable = ({
  rows,
  setRows,
  customerRefs,
  classRefs,
  itemRefs,
  vendorRefs,
  creditCardAccount,
  selection,
  changeSelection,
  employeeRefs,
  rowSelectionEnabled,
  disableEditAndDelete
}) => {
  console.log('rows aayeko cha ta?', rows);

  const classes = useStyles();
  const [columns] = useState([
    {
      name: 'EmployeeRef',
      title: 'Employee Name',
      getCellValue: (row) => (row?.EmployeeRef ? row?.EmployeeRef?.name : undefined)
    },
    { name: 'KeyPayEmployeeId', title: 'External Id' },
    {
      name: 'Day',
      title: 'Day',
      required: true,
      getCellValue: (row) => {
        if (row?.ReceiptDate) {
          const columnDay = format(new Date(row?.ReceiptDate), 'EEEE');
          return columnDay;
        }
      }
    },
    { name: 'ReceiptDate', title: 'ReceiptDate', required: true },
    { name: 'PaidBy', title: 'PaidBy', required: true },

    { name: 'Description', title: 'Description', required: true },
    { name: 'status', title: 'Status', required: true },
    {
      name: 'CustomerRef',
      title: 'Customer Ref',
      getCellValue: (row) => (row?.CustomerRef ? row?.CustomerRef?.name : undefined),
      required: true
    },
    {
      name: 'ClassRef',
      title: 'Service',
      getCellValue: (row) => (row?.ClassRef ? row?.ClassRef?.name : undefined),
      required: true
    },
    {
      name: 'ItemRef',
      title: 'Item Ref',
      getCellValue: (row) => (row?.ItemRef ? row?.ItemRef?.name : undefined),
      required: true
    },
    {
      name: 'AccountRef',
      title: 'Account Ref',
      getCellValue: (row) => (row?.AccountRef ? row?.AccountRef?.name : undefined),
      required: true
    },
    { name: 'Price', title: 'Price', required: true },
    { name: 'Qty', title: 'Qty', required: true },
    { name: 'GlobalTaxCalculation', title: 'Global Tax Calculation' },
    { name: 'purchaseOrder', title: 'Purchase Order' },
    { name: 'DocNumber', title: 'BillNumber' },
    { name: 'BillableStatus', title: 'Billable Status' },
    { name: 'variationNote', title: 'Variation Note' },
    { name: 'ExpenseReceiptUrl', title: 'Expense Receipt' },
    {
      name: 'VendorRef',
      title: 'Supplier',
      getCellValue: (row) => (row?.VendorRef ? row?.VendorRef?.name : undefined)
    },
    { name: 'email', title: 'Email' }
  ]);

  const [expenseColumnWidths, setExpenseColumnWidths] = useState([
    { columnName: 'EmployeeRef', width: 200 },
    { columnName: 'KeyPayEmployeeId', width: 200 },
    { columnName: 'Day', width: 180 },
    { columnName: 'ReceiptDate', width: 180 },
    { columnName: 'PaidBy', width: 180 },
    { columnName: 'Description', width: 180 },
    { columnName: 'status', width: 180 },
    { columnName: 'CustomerRef', width: 200 },
    { columnName: 'ClassRef', width: 200 },
    { columnName: 'ItemRef', width: 200 },
    { columnName: 'AccountRef', width: 200 },
    { columnName: 'Price', width: 200 },
    { columnName: 'Qty', width: 200 },
    { columnName: 'GlobalTaxCalculation', width: 200 },
    { columnName: 'purchaseOrder', width: 200 },
    { columnName: 'DocNumber', width: 200 },
    { columnName: 'BillableStatus', width: 200 },
    { columnName: 'variationNote', width: 200 },
    { columnName: 'ExpenseReceiptUrl', width: 200 },
    { columnName: 'VendorRef', width: 200 },
    { columnName: 'email', width: 200 }
  ]);

  //user cannot edit employee ref
  const [editingStateColumnExtensions] = useState([
    { columnName: 'KeyPayEmployeeId', editingEnabled: false },
    { columnName: 'status', editingEnabled: false },
    { columnName: 'EmployeeRef', editingEnabled: false },
    { columnName: 'VendorRef', editingEnabled: false },
    { columnName: 'jobTitle', editingEnabled: false },
    { columnName: 'email', editingEnabled: false },
    { columnName: 'VendorRef', editingEnabled: false },
    { columnName: 'Day', editingEnabled: false },
    { columnName: 'DocNumber', editingEnabled: false },
    { columnName: 'ExpenseReceiptUrl', editingEnabled: false }
  ]);

  const [sorting, getSorting] = useState([]);
  const [editingRowIds, getEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [pageSizes] = useState([25, 50, 0]);
  const [expenseColumnOrder, setExpenseColumnOrder] = useState([
    'EmployeeRef',
    'KeyPayEmployeeId',
    'Day',
    'ReceiptDate',
    'DocNumber',
    'PaidBy',
    'Description',
    'status',
    'CustomerRef',
    'ClassRef',
    'ItemRef',
    'AccountRef',
    'Price',
    'Qty',
    'GlobalTaxCalculation',
    'purchaseOrder',
    'BillableStatus',
    'variationNote',
    'ExpenseReceiptUrl',
    'VendorRef',
    'email'
  ]);

  const dateColumns = ['ReceiptDate'];
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['image', 'email', 'KeyPayEmployeeId']);
  const [validationErrors, setValidationErrors] = useState({});

  const [totalSummaryItems] = useState([
    { columnName: 'ReceiptDate', type: 'ReceiptDate' },
    { columnName: 'KeyPayEmployeeId', type: 'KeyPayEmployeeId' },
    { columnName: 'EmployeeRef', type: 'EmployeeRef' }
  ]);

  const [totalDateCount, setTotalDateCount] = useState(0);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);

  const [leftFixedColumns] = useState([TableEditColumn.COLUMN_TYPE]);

  const ExpenseReceiptUrlColumns = ['ExpenseReceiptUrl'];

  const changeAddedRows = (value) =>
    setAddedRows(
      value.map((row) =>
        Object.keys(row).length
          ? row
          : {
              amount: 0,
              discount: 0,
              saleDate: '',
              product: '',
              region: '',
              customer: ''
            }
      )
    );

  //to calculate timesheet date
  const summaryCalculator = (type, rows, getValue) => {
    // eslint-disable-next-line no-undef
    const selectionSet = new Set(selection);
    const selectedRows = rows.filter((row) => selectionSet.has(row?.id));
    if (type === 'ReceiptDate') {
      const changedRows = selectedRows.length
        ? selectedRows.map((row) => new Date(getValue(row)).getTime()).filter(Number)
        : rows.map((row) => new Date(getValue(row)).getTime()).filter(Number);
      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      setTotalDateCount(duplicateRemovedRowsLength);
      return duplicateRemovedRowsLength;
    }
    if (type === 'KeyPayEmployeeId') {
      const changedRows = selectedRows.length
        ? selectedRows
            .map((row) => {
              if (!getValue(row)) return;
              getValue(row);
            })
            .filter(Number)
        : rows
            .map((row) => {
              if (!getValue(row)) return;
              getValue(row);
            })
            .filter(Number);
      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      return duplicateRemovedRowsLength;
    }

    if (type === 'EmployeeRef') {
      const changedRows = selectedRows.length
        ? selectedRows.map((row) => {
            if (!getValue(row)) return;
            return getValue(row);
          })
        : rows.map((row) => {
            if (!getValue(row)) return;
            return getValue(row);
          });

      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      setTotalEmployeeCount(duplicateRemovedRowsLength);
      return duplicateRemovedRowsLength;
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  const formatlessSummaryTypes = ['ReceiptDate', 'KeyPayEmployeeId', 'EmployeeRef'];

  const messages = {
    ReceiptDate: 'TotalDays',
    KeyPayEmployeeId: 'TotalEmployees',
    EmployeeRef: 'TotalEmployees'
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ];
    }

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

      const changedRowId = Object.keys(changed)[0];
      const changedRow = changedRows.find((r) => r.id === changedRowId);

      if (changedRow) {
        const billableStatusValid = isBillableStatusValid(changedRow.BillableStatus, changedRow.variationNote);

        if (!billableStatusValid) {
          toast.error(`'Billable' status must have a variation note`);
          setRows(rows);

          return;
        }
      }

      // update edited data
      if (changed[changedRowId]) {
        expenseService
          .updateExpense(changedRowId, changed[changedRowId])
          .then(({ data }) => {
            const updatedRows = changedRows.map((row) => {
              if (row.id === data.data._id) {
                row.ReceiptDate = new Date(data.data.ReceiptDate);
              }
              return row;
            });
            setRows(updatedRows);

            toast.success('Successfully edited expense');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || "Couldn't update expense. Something's wrong...");
          });
      }
    }

    //when delete button is clicked
    if (deleted) {
      // eslint-disable-next-line no-undef
      if (window.confirm('Are you sure you want to delete this expense item?')) {
        changedRows = deleteRows(deleted);

        expenseService
          .deleteExpense(deleted[0])
          .then(() => {
            toast.success('Successfully deleted selected expense item');
            setRows(changedRows);
          })
          .catch((error) => {
            toast.error(error?.message || 'Could not delete selected expense item');
          });
      }
    }
  };

  const editCellComponent = useCallback(
    (props) => {
      return (
        <EditCell
          {...props}
          classRefs={classRefs}
          customerRefs={customerRefs}
          employeeRefs={employeeRefs}
          itemRefs={itemRefs}
          vendorRefs={vendorRefs}
          creditCardAccount={creditCardAccount}
        />
      );
    },
    [classRefs, customerRefs, employeeRefs, creditCardAccount, itemRefs, vendorRefs]
  );

  const validate = (rows, columns) =>
    Object.entries(rows).reduce(
      (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columns.some((column) => column.required && row[column.name] === '')
      }),
      {}
    );

  const onEdited = (edited) => setValidationErrors(validate(edited, columns));

  const onAdded = (added) => setValidationErrors(validate(added, columns));

  return (
    <div>
      <div style={{ margin: '10px 0px' }}>
        <div>Total rows selected: {selection ? selection.length : 0}</div>
      </div>
      <Paper className={classes.root}>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState sorting={sorting} onSortingChange={getSorting} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <SelectionState selection={selection} onSelectionChange={changeSelection} />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={getEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={(changes) => {
              onEdited(changes);
              setRowChanges(changes);
            }}
            addedRows={addedRows}
            onAddedRowsChange={(added) => {
              onAdded(added);
              changeAddedRows(added);
            }}
            // onRowChangesChange={onEdited}
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSorting />
          <IntegratedPaging />
          {/* <IntegratedSelection /> */}
          <PatchedIntegratedSelection rowSelectionEnabled={rowSelectionEnabled} />
          <IntegratedSummary calculator={summaryCalculator} />
          {/* <CurrencyTypeProvider for={currencyColumns} />
      <PercentTypeProvider for={percentColumns} /> */}
          <DragDropProvider />
          <DateTypeProvider for={dateColumns} />
          <ImageProvider for={ExpenseReceiptUrlColumns} />

          {/* <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} /> */}
          {/* <SearchState value={searchValue} onValueChange={setSearchState} /> */}
          {/* <IntegratedFiltering /> */}
          <Table cellComponent={Cell} />

          {/* TableSelection replaced by PatchedTableSelection */}
          {/* <TableSelection showSelectAll cellComponent={SelectionCell} /> */}
          <PatchedTableSelection showSelectAll highlightRow rowSelectionEnabled={rowSelectionEnabled} />

          <TableColumnReordering order={expenseColumnOrder} onOrderChange={setExpenseColumnOrder} />
          <TableColumnResizing
            defaultColumnWidths={expenseColumnWidths}
            onColumnWidthsChange={setExpenseColumnWidths}
          />
          <TableHeaderRow
            contentComponent={(props) => (
              <TableHeaderContent {...props} totalEmployeeCount={totalEmployeeCount} totalDateCount={totalDateCount} />
            )}
            showSortingControls
          />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <Toolbar />
          {/* <SearchPanel /> */}
          <ColumnChooser />

          <TableEditRow cellComponent={editCellComponent} />

          <TableEditColumn
            width={170}
            showEditCommand
            showDeleteCommand
            // commandComponent={Command}
            cellComponent={(props) => (
              <EditCommandCell {...props} disableEditAndDelete={disableEditAndDelete} errors={validationErrors} />
            )}
          />

          <TableSummaryRow formatlessSummaryTypes={formatlessSummaryTypes} messages={messages} />

          <TableFixedColumns leftColumns={leftFixedColumns} />
          <PagingPanel pageSizes={pageSizes} />
          <ExportPanel startExport={startExport} />
        </Grid>

        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            hiddenColumnNames={hiddenColumnNames}
            columns={columns}
            selection={selection}
            onSave={onSave}
            getRowId={getRowId}
            customizeCell={customizeCell}
          />
        </div>
      </Paper>
    </div>
  );
};

export default AdminExpenseTable;
