import { format } from 'date-fns';

export function formattedExpensePayload(expenseFormData, expenseRow) {
  const expensePayload = [];
  const vendor = {
    value: expenseFormData.vendor?.id || expenseFormData.vendor?.value,
    name: expenseFormData.vendor?.label || expenseFormData.vendor?.name
  };
  const DocNumber = validDocNumber(expenseFormData?.date, vendor);

  for (const row in expenseRow) {
    const expenseItem = {
      PaidByEmployee: expenseFormData.paidByEmployee,
      PaidByCompany: expenseFormData.paidByCompany,
      AccountRef: expenseFormData.creditCardAccount,
      VendorRef: vendor,
      ReceiptDate: expenseFormData.date,
      CustomerRef: expenseFormData.client,
      ClassRef: expenseRow[row].ClassRef,
      ItemRef: expenseRow[row].ItemRef,
      Qty: expenseRow[row].Qty,
      Price: expenseRow[row].Price,
      BillableStatus: expenseFormData.isBillable,
      Description: expenseRow[row].Description,
      variationNote: expenseFormData.variationNote,
      DocNumber: DocNumber,
      GlobalTaxCalculation: expenseFormData.globalTaxCalculation?.value,
      PurchaseOrder: expenseFormData.purchaseOrder
    };
    expensePayload.push(expenseItem);
  }

  return expensePayload;
}

function validDocNumber(date, vendor) {
  const DocNumber = format(date, 'dd/MM/yy') + ' ' + vendor?.name; // receipt date and vendor name

  if (DocNumber.length > 20) {
    return DocNumber.slice(0, 20);
  }
  return DocNumber;
}

export function isPriceValid(price) {
  if (!isNaN(price) && typeof price !== 'boolean') return true;
  return false;
}

export function isQtyValid(qty) {
  if (!isNaN(qty) && typeof qty !== 'boolean') return true;
  return false;
}

export function isBillableStatusValid(billableStatus, variationNote) {
  if (billableStatus && variationNote) return true;

  if (!billableStatus && !variationNote) return true;

  return false;
}

export function isCustomerRefValid(clientRef) {
  if (clientRef) return true;
  return false;
}

export function isClassRefValid(classRef) {
  if (classRef) return true;
  return false;
}

export function isItemRefValid(itemRef) {
  if (itemRef) return true;
  return false;
}

export function isDescriptionValid(desc) {
  if (desc !== '' && desc.trim() !== '') return true;
  return false;
}

export function isPaidByValid(paidByEmployee, paidByCompany) {
  if ((paidByCompany === true && paidByEmployee === false) || (paidByCompany === false && paidByEmployee === true))
    return true;
  return false;
}

export function isPaidByCompanyValid(expenseFormData) {
  if (expenseFormData.paidByCompany === false && expenseFormData.paidByEmployee === true) return true;

  if (expenseFormData.paidByCompany === true && expenseFormData.paidByEmployee === false) {
    if (expenseFormData.creditCardAccount && expenseFormData.vendor) return true;
  }

  return false;
}

export function isGlobalTaxCalculationValid(expenseFormData) {
  if (expenseFormData.globalTaxCalculation) return true;
  return false;
}

export function isExpenseRowValid(rows) {
  for (const element of rows) {
    // Check if the element has all the required properties
    if (
      !('ItemRef' in element) ||
      !('ClassRef' in element) ||
      !('Price' in element) ||
      !('Qty' in element) ||
      !('Description' in element)
    ) {
      return { valid: false, message: 'One of the field in table is empty.' };
    }

    // Convert Price and Qty to numbers (if they are numeric strings)
    const parsedPrice = parseFloat(element.Price);
    const parsedQty = parseFloat(element.Qty);

    // Check if Price and Qty are valid numbers
    if (isNaN(parsedPrice) || isNaN(parsedQty)) {
      return { valid: false, message: 'Price and Qty must be number' };
    }

    // Check if any of the properties are empty
    if (!element.ItemRef.name || !element.ItemRef.value) {
      return { valid: false, message: 'ItemRef must be selected' };
    }

    if (!element.ClassRef.name || !element.ClassRef.value) {
      return { valid: false, message: 'ClassRef must be selected' };
    }
  }

  return { valid: true };
}

//now need to create few other models:
export function formatReceiptText(extractedText, vendorRefOptions) {
  const includingGST = ['Total Inc Tax', 'Total including GST', 'Total Inc Tax:'];
  const cardType = ['Card:', 'Card Types:'];
  const subTotal = ['Subtotal', 'SubTotal', 'Subtotal:', 'SubTotal:'];
  const descriptionAmount = ['Amount', 'EPA/QDN:'];

  //TODO: uncomment this and comment demo data Normalize text: split into lines and trim whitespace
  const lines = extractedText
    .split('\n')
    .map((line) => line.trim())
    .filter(Boolean);

  const extractVendor = vendorRefOptions.filter((vendor) => lines.includes(vendor.label));

  //yedi line create garna sakiyena vani sabai vanda thulo $ nikalni tesma 10% ghatayera siddai item ma rakhdini, ani jaile

  //total including GST
  const totalIncludingGST = lines
    .map((line, index) => (includingGST.includes(line) ? lines[index + 1] : null)) // Get the next line if there's a match
    .filter(Boolean)[0]; // Remove `null` values
  const extractTotalIncludingGST = totalIncludingGST?.slice(1);

  //receipt date
  const findDate = lines.filter((line) => isValidDateFns(line)); // YYYY-MM-DD format
  const extractDate = formatDate(findDate[0]);

  //card used/ card type
  const cardUsed = lines
    .map((line, index) => (cardType.some((keyword) => line.includes(keyword)) ? lines[index] : null))
    .filter(Boolean)[0];

  const extractCardType = cardUsed ? cardUsed.split(':')[1] : null;

  //Reference number
  const reference = lines
    .map((line, index) => (line.includes('Reference:') ? lines[index] : null)) // Get the next line if there's a match
    .filter(Boolean)[0]; // Remove `null` values

  const extractReferenceNumber = reference ? reference.split(':')[1] : null;

  //description, qty, price:
  const lineDescriptionAmountIndex = lines
    .map((line, index) => (descriptionAmount.some((keyword) => line.includes(keyword)) ? index + 1 : null))
    .filter(Boolean)[0];
  //this is the index of amount start from this line index to subtotal

  //Subtotal
  const lineSubTotalIndex = lines
    .map((line, index) => (subTotal.some((keyword) => line.includes(keyword)) ? index : null))
    .filter(Boolean)[0];

  const itemDetails = lines.slice(lineDescriptionAmountIndex, lineSubTotalIndex);

  const expenseRows = itemDetails
    .map((item, index) => {
      const obj = {};
      if (index % 2 !== 0) {
        return null;
      } else if (index % 2 === 0) {
        //description got quantity, separate quantity and description
        const quantity = item.split(' x');

        obj.Description = quantity[0];
        obj.Qty = quantity[1] ? Number(quantity[1]) : 1;
        obj.id = index + 1;
        const Price = itemDetails[index + 1];
        obj.Price = Number(Price.slice(1));
        return obj;
      }
    })
    .filter(Boolean);

  return { expenseRows, extractCardType, extractDate, extractTotalIncludingGST, extractReferenceNumber, extractVendor };
}

//date example:
// const dateOne = "2025-02-07 13:03:12";
// const dateTwo = "20/12/2024 8:25 AM";
//validate the date
function isValidDateFns(date) {
  const splitDate = date.split(' ')[0]; // Extract date part

  if (/^\d{2}\/\d{2}\/\d{4}$/.test(splitDate)) {
    // Format: DD/MM/YYYY
    let [day, month, year] = splitDate.split('/');
    const convertedDate = `${year}-${month}-${day}`; // Convert to YYYY-MM-DD
    const formattedDate = new Date(convertedDate);
    return !isNaN(formattedDate.getTime());
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(splitDate)) {
    // Format: YYYY-MM-DD
    const formattedDate = new Date(splitDate);
    return !isNaN(formattedDate.getTime());
  }

  return false; // If it doesn't match any known date format
}

function formatDate(date) {
  const splitDate = date.split(' ')[0]; // Extract date part

  if (/^\d{2}\/\d{2}\/\d{4}$/.test(splitDate)) {
    // Format: DD/MM/YYYY
    const extractDate = splitDate.split('/').reverse().join('-');
    return extractDate;
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(splitDate)) {
    // Format: YYYY-MM-DD
    return splitDate;
  }
}
