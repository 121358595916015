import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import expenseService from '../../../../services/ExpenseService';
import { getFormattedTableRows } from '../../../MyExpense/table/helper';

export default function useFetchAllExpenseStatus(filter, setRows) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [expenseStatusList, setExpenseStatusList] = useState([]);

  useEffect(() => {
    const fetchAllExpense = async () => {
      try {
        const expenseRecords = await expenseService.getAllExpenses();
        const formattedExpense = getFormattedTableRows(expenseRecords.data.data);

        setLoading(false);

        const filteredRows = formattedExpense.filter((row) => {
          if (
            (filter.startDate === null || new Date(row.expenseCreatedDate) >= new Date(filter.startDate).getTime()) &&
            new Date(row.expenseCreatedDate) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }

          if (filter.headerStatusChange) {
            if (
              (filter.startDate === null || new Date(row.expenseCreatedDate) >= new Date(filter.startDate).getTime()) &&
              new Date(row.expenseCreatedDate) <= addDays(new Date(filter.endDate), 1).getTime()
            ) {
              return true;
            }
          }
        });

        const withEmployeeExpense = filteredRows.filter((expense) => expense.status === 'WithEmployee');
        if (filter.expenseStatus === 'WithEmployee') {
          setRows(withEmployeeExpense);
        }
        const withApproverExpense = filteredRows.filter((expense) => expense.status === 'WithApprover');
        if (filter.expenseStatus === 'WithApprover') {
          setRows(withApproverExpense);
        }
        const approvedExpense = filteredRows.filter((expense) => expense.status === 'Approved');
        if (filter.expenseStatus === 'Approved') {
          setRows(approvedExpense);
        }

        const withEmployeeExpenses = withEmployeeExpense.map((d) => d.Price);
        let withEmployeeTotalExpense = 0;
        if (withEmployeeExpenses.length) {
          withEmployeeTotalExpense = withEmployeeExpenses.reduce((a, c) => Number(a) + Number(c));
        }

        const withApproverExpenses = withApproverExpense.map((d) => d.Price);
        let withApproverTotalExpenses = 0;
        if (withApproverExpenses.length) {
          withApproverTotalExpenses = withApproverExpenses.reduce((a, c) => Number(a) + Number(c));
        }

        const approvedExpenses = approvedExpense?.map((d) => d.Price);
        let approvedTotalExpenses = 0;
        if (approvedExpenses.length) {
          approvedTotalExpenses = approvedExpenses?.reduce((a, c) => Number(a) + Number(c));
        }

        setExpenseStatusList([
          {
            label: 'WithEmployee',
            totalExpenses: `$${withEmployeeTotalExpense}`,
            totalCount: withEmployeeExpense.length
          },
          {
            label: 'WithApprover',
            totalExpenses: `$${withApproverTotalExpenses}`,
            totalCount: withApproverExpense.length
          },
          {
            label: 'Approved',
            totalExpenses: `$${approvedTotalExpenses}`,
            totalCount: approvedExpense.length
          },
          {
            label: 'All'
          }
        ]);
      } catch (error) {
        setError(error.message || "couldn't fetch all expense");
      } finally {
        setLoading(false);
      }
    };

    fetchAllExpense();
  }, [filter.endDate, filter.startDate, filter.expenseStatus, setRows, filter.headerStatusChange]);

  return { expenseStatusList, error, loading };
}
