import React, { useEffect, useState } from 'react';
import { startOfWeek, lastDayOfWeek, format } from 'date-fns';
import { Toaster, toast } from 'react-hot-toast';

import useFetchExpenseFilter from './hooks/useFetchExpenseFilter';
import useFetchAllExpenseStatus from './hooks/useFetchAllExpenseStatus';
import ExpenseFilter, { expensePeriodOptions } from './filter/ExpenseFilter';
import AdminExpenseTable from './table/AdminExpenseTable';
import useFetchCustomerRefRecords from '../../MyTime/hooks/useFetchCustomerRefs';
import useFetchClassRefRecords from '../../MyTime/hooks/useFetchClassRefs';
import useFetchEmployeeRefRecords from '../AdminTimesheet/hooks/useFetchEmployeeRefs';
import useFetchItemRefs from '../../MyTime/hooks/useFetchItemRefs';
import useFetchVendorRefRecords from '../../MyTime/hooks/useFetchVendorRefs';
import { useFetchCreditCardAccounts } from '../../MyTime/hooks/useFetchCreditCardAccounts';
import ExpenseStatus from './header/ExpenseStatus';

import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AlertDialog from './dialogbox/AlertDialog';
import SearchIcon from '@material-ui/icons/Search';
import expenseService from '../../../services/ExpenseService';
import { validateDateDifference } from '../AdminTimesheet/helper/helper';
import billService from '../../../services/BillService';
import ButtonMenu from './header/ButtonMenu';

const useStyles = makeStyles(() => ({
  filterButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '2rem',
    cursor: 'pointer'
  },
  buttonWrapper: {
    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  approveButton: {
    borderRadius: ' 12px 0 0 12px',
    borderRight: '1px solid white'
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  searchContainer: {
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '200px',
    float: 'right',
    paddingLeft: '20px',
    marginLeft: '1rem'
  },
  resetButton: {
    marginLeft: '1rem',
    backgroundColor: '#fff',
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  }
}));

const AdminExpense = () => {
  const classes = useStyles();

  const { customerRefs } = useFetchCustomerRefRecords();
  const { classRefs } = useFetchClassRefRecords();
  const { employeeRefs } = useFetchEmployeeRefRecords();
  const { itemRefs } = useFetchItemRefs();
  const { vendorRefs } = useFetchVendorRefRecords();
  const { creditCardAccount } = useFetchCreditCardAccounts();

  const [filter, setFilter] = useState({
    expensePeriod: expensePeriodOptions[0],
    startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
    endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
    expenseStatus: 'All',
    searchQuery: '',
    headerStatusChange: false
  });

  const { rows, setRows } = useFetchExpenseFilter(filter);
  const { expenseStatusList } = useFetchAllExpenseStatus(filter, setRows);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selection, setSelection] = useState([]);
  const [submitToQBOLoading, setSubmitToQBOLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [sendRemainderClicked, setSendRemainderClicked] = useState(false);
  const [buttonList] = useState(['Send Reminder', 'Reject and send reminder', 'Send back to WithApprover']);

  let rowSelectionEnabled = (row) =>
    row.status === 'Approved' || row.status === 'WithEmployee' || row.status === 'WithApprover';

  if (filter.expenseStatus === 'All') {
    rowSelectionEnabled = (row) => row.status !== 'Approved';
  }

  useEffect(() => {
    setSelection([]);
  }, [filter.expenseStatus]);

  const changeSelection = (sel) => {
    setSelection(sel);
  };

  const handleOptions = (e) => {
    if (!selection.length) return toast.error(`Please Select Expense to ${e.target.innerText}.`);

    if (e.target.innerText === 'Send Reminder' || e.target.textContent === 'Send Reminder') {
      setDialogOpen(true);
      setSendRemainderClicked(true);
      setDialogContent('Do you want to Send Reminder to Employee?');
      setAnchorEl(null);
    }

    if (e.target.innerText === 'Reject and send reminder' || e.target.textContent === 'Reject and send reminder') {
      handleRejectAndSendReminder();
    }

    if (e.target.innerText === 'Send back to WithApprover' || e.target.textContent === 'Send back to WithApprover') {
      handleSendBackToWithApprover();
    }
  };

  const handleRejectAndSendReminder = () => {};

  const handleSendBackToWithApprover = () => {
    setAnchorEl(null);
    // eslint-disable-next-line no-undef
    if (confirm('Selected Approved expense item will be sent back to WithApprover.')) {
      setFilter({ ...filter, headerStatusChange: true });

      expenseService
        .changeExpensesStatus(selection)
        .then((data) => {
          const filteredExpense = rows.filter((row) => row.id !== data.data._id);
          setRows(filteredExpense);
          toast.success('Successfully sent back expense items to WithApprover status');
        })
        .catch(() => {
          toast.error('Could not send back to WithApprover.');
        })
        .finally(() => {
          setFilter({ ...filter, headerStatusChange: false });
        });
    }
  };

  const handleSendRemainderToEmployee = () => {};

  //submit expense: create expense bill in qbo
  const handleExpenseSubmitToQBO = () => {
    if (!validateDateDifference(filter.startDate, filter.endDate)) {
      return toast.error('Selected start date and end should be at least seven or multiple of seven days');
    }

    let filteredUserIds = rows.filter((r) => selection.includes(r.id)).map((r) => r.EmployeeRef.value);
    // eslint-disable-next-line no-undef
    filteredUserIds = [...new Set(filteredUserIds)];

    //filteredDocNumbers pani patham
    let filteredDocNumbers = rows.filter((r) => selection.includes(r.id)).map((r) => r.DocNumber);
    // eslint-disable-next-line no-undef
    filteredDocNumbers = [...new Set(filteredDocNumbers)];

    // const weekStartDate = filter.startDate;
    // const weekEndDate = format(new Date(filter.endDate), 'yyyy-MM-dd');

    const billPayload = { filteredUserIds, selection, filteredDocNumbers };

    setSubmitToQBOLoading(true);
    setDialogOpen(false);

    billService
      .createExpenseBill(billPayload)
      .then((data) => {
        if (data.data.failedUserIds.length) {
          const failedUserIds = data.data.failedUserIds.map((item) => item.employeeId);
          const usersDisplayNames = rows
            .filter((r) => failedUserIds.includes(r.EmployeeRef.value))
            .map((r) => r.EmployeeRef.name);
          // eslint-disable-next-line no-undef
          const uniqueNames = [...new Set(usersDisplayNames)];

          const errorMessage = data.data.failedUserIds[0]?.error?.Fault?.Error[0]?.Detail || '';
          return toast.error(
            `Could not create bill of following user ${uniqueNames.join(',')} ${errorMessage}` ||
              'Could not create expense bill of selected expense.'
          );
        }
        if (data.data.succeededUserIds.length === filteredUserIds.length || data.data.succeededUserIds.length > 0) {
          return toast.success('All users bill created.');
        }
      })
      .catch((error) => {
        if (error.response?.data?.length) {
          const userIdsData = error.response.data;

          const usersDisplayNames = rows
            .filter((r) => userIdsData.includes(r.EmployeeRef.value))
            .map((r) => r.EmployeeRef.name);

          return toast.error(
            `Could not create bill of following user ${usersDisplayNames.join(',')} ${
              error?.response?.data?.data?.Fault?.Error[0]?.Detail
            }`
          );
        }

        let errorMessage = error?.response?.data?.data?.Fault?.Error[0]?.Detail
          ? error?.response?.data?.data?.Fault?.Error[0]?.Detail
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : "Couldn't create bill";
        return toast.error('Could not create expense bill of selected expenses' || errorMessage);
      })
      .finally(() => {
        setSubmitToQBOLoading(false);
        setSelection([]);
      });
  };

  //change "WithEmployee"/"WithApprover" to "Approved"
  const handleApproveClick = (e) => {
    e.preventDefault();

    if (!selection.length) {
      return toast.error('Please select timesheet to approve');
    }
    setFilter({ ...filter, headerStatusChange: true });

    expenseService
      .updateExpenseStatusByAdmin(selection)
      .then(() => {
        const updatedRows = rows.filter((r) => {
          return !selection.includes(r.id);
        });

        setRows(updatedRows);
        setSelection([]);
        toast.success('Successfully approved expense');
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Could not approve expense');
      });
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <ExpenseStatus
        expenseStatusList={expenseStatusList}
        filter={filter}
        setFilter={setFilter}
        setSelection={setSelection}
      />

      <div className={classes.filterButtonWrapper}>
        <div className={classes.filterWrapper}>
          <ExpenseFilter filter={filter} setFilter={setFilter} />
          <Button
            onClick={() =>
              setFilter({
                ...filter,
                expensePeriod: expensePeriodOptions[0],
                startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
                endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
                expenseStatus: 'All',
                searchQuery: ''
              })
            }
            className={classes.resetButton}
          >
            <RefreshIcon />
            Reset
          </Button>
        </div>
        <div className={classes.buttonWrapper} style={{ backgroundColor: submitToQBOLoading ? 'white' : '' }}>
          {filter.expenseStatus !== 'Approved' ? (
            <Button variant="contained" color="primary" onClick={handleApproveClick} className={classes.approveButton}>
              Approve
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!selection.length) return toast.error('Please select expense rows to submit');
                setDialogContent(' Are you sure you want to create bill with selected expense?');
                setDialogOpen(true);
              }}
              className={classes.approveButton}
              disabled={submitToQBOLoading}
            >
              {submitToQBOLoading && (
                <>
                  <CircularProgress size={20} style={{ color: 'white' }} />
                  &nbsp;&nbsp;&nbsp;
                </>
              )}
              Submit
            </Button>
          )}
          <ButtonMenu
            buttonList={buttonList}
            handleOptions={handleOptions}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            filter={filter}
          />
        </div>
      </div>

      <AlertDialog
        handleAgreeClick={sendRemainderClicked ? handleSendRemainderToEmployee : handleExpenseSubmitToQBO}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        sendRemainderClicked={sendRemainderClicked}
        dialogContent={dialogContent}
      />

      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          type="text"
          value={filter.searchQuery}
          onChange={(e) => setFilter({ ...filter, searchQuery: e.target.value })}
          placeholder="Search..."
          style={{ border: 'none', outline: 'none' }}
        />
      </div>

      <AdminExpenseTable
        rows={rows}
        setRows={setRows}
        customerRefs={customerRefs}
        classRefs={classRefs}
        employeeRefs={employeeRefs}
        itemRefs={itemRefs}
        vendorRefs={vendorRefs}
        creditCardAccount={creditCardAccount}
        selection={selection}
        rowSelectionEnabled={rowSelectionEnabled}
        changeSelection={changeSelection}
        disableEditAndDelete={(row) => {
          return row.status === 'Approved';
        }}
        filter={filter}
      />
    </div>
  );
};

export default AdminExpense;
