/* eslint-disable react/prop-types */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import saveAs from 'file-saver';
import * as Constants from '../../Constant';
import axios from 'axios';

import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableSelection,
  DragDropProvider,
  TableColumnReordering,
  TableColumnResizing,
  ColumnChooser,
  TableColumnVisibility,
  ExportPanel
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  IntegratedSelection,
  SelectionState,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import UserTab from './UserTab';
import UserGeneral from '../tabs/General/UserGeneral';
import UserAccounting from '../tabs/Accounting/UserAccounting';
import useFetchVendorRecords from '../hooks/useFetchVendorRecords';
import useFetchKeyPayEmployeeRecords from '../hooks/useFetchKeyPayEmployeeRecords';
import employeeService from '../../../services/EmployeeService';
import { toast } from 'react-hot-toast';
import {
  getFormattedEmployeeList,
  getFormattedEmployeePayload,
  updateEmployeeApprovalDetails,
  updateEmployeeType
} from '../helper/helper';
import UserUniform from '../tabs/Uniform/UserUniform';
import UserQualification from '../tabs/Qualification/UserQualification';
import SuperAdmin from '../tabs/SuperAdmin/SuperAdmin';

const Popup = ({
  row,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
  userTabIndex,
  setUserTabIndex,
  vendorRecords,
  keyPayEmployeeRecords,
  selectedEmployeeToRegister,
  setSelectedEmployeeToRegister,
  selectedUserType,
  setSelectedUserType,
  approvalRequired,
  setApprovalRequired,
  selectedApprover,
  setSelectedApprover,
  portalAccess,
  setPortalAccess,
  portalAccessDialog,
  setPortalAccessDialog,
  setRows,
  rows
}) => {
  useEffect(() => {
    if (open) {
      setSelectedUserType({
        value: selectedUserType.value || row.employeeType,
        label: selectedUserType.label || row.employeeType
      });
    }
  }, [row.employeeType, setSelectedUserType, selectedUserType.label, selectedUserType.value, open]);

  useEffect(() => {
    if (open) {
      setPortalAccess(row.portalAccess);
    }
  }, [open, row.portalAccess, setPortalAccess, portalAccess]);

  useEffect(() => {
    if (open) {
      const fetchEmployeeExtended = async () => {
        try {
          // call employeeExtended to get this user approval and show in required approval
          const result = await employeeService.getUserApprover(row.id);

          if (result.data.data) {
            const approverData = result.data.data[0];

            const userApprover = rows.filter((row) => row.id == approverData.approverId)[0];
            const selectedApprover = {
              value: userApprover.DisplayName,
              label: userApprover.DisplayName,
              id: userApprover.id
            };

            setApprovalRequired(approverData.approvalRequired);
            setSelectedApprover(selectedApprover);
            //  setPortalAccess(row?.portalAccess);
          }
        } catch (error) {
          // eslint-disable-next-line no-console, no-undef
          console.log('error', error);
        }
      };
      fetchEmployeeExtended();
    }
  }, [open, row.id, rows, setApprovalRequired, setSelectedApprover]);

  return (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <UserTab userTabIndex={userTabIndex} setUserTabIndex={setUserTabIndex} />

      {/* General and Accounting is the content of tab  */}
      {userTabIndex === 0 && (
        <UserGeneral
          row={row}
          onChange={onChange}
          onApplyChanges={onApplyChanges}
          onCancelChanges={onCancelChanges}
          vendorRecords={vendorRecords}
          keyPayEmployeeRecords={keyPayEmployeeRecords}
          selectedEmployeeToRegister={selectedEmployeeToRegister}
          setSelectedEmployeeToRegister={setSelectedEmployeeToRegister}
          selectedUserType={selectedUserType}
          setSelectedUserType={setSelectedUserType}
          approvalRequired={approvalRequired}
          setApprovalRequired={setApprovalRequired}
          selectedApprover={selectedApprover}
          setSelectedApprover={setSelectedApprover}
          open={open}
          portalAccessDialog={portalAccessDialog}
          setPortalAccessDialog={setPortalAccessDialog}
          portalAccess={portalAccess}
          setPortalAccess={setPortalAccess}
          setRows={setRows}
          rows={rows}
        />
      )}

      {userTabIndex === 1 && <SuperAdmin row={row} onCancelChanges={onCancelChanges} />}

      {userTabIndex === 2 && (
        <UserAccounting
          row={row}
          onCancelChanges={onCancelChanges}
          setUserTabIndex={setUserTabIndex}
          vendorRecords={vendorRecords}
          keyPayEmployeeRecords={keyPayEmployeeRecords}
        />
      )}

      {userTabIndex === 3 && (
        <UserQualification
          row={row}
          onCancelChanges={onCancelChanges}
          setUserTabIndex={setUserTabIndex}
          vendorRecords={vendorRecords}
          keyPayEmployeeRecords={keyPayEmployeeRecords}
        />
      )}

      {userTabIndex === 4 && (
        <UserUniform
          row={row}
          onCancelChanges={onCancelChanges}
          setUserTabIndex={setUserTabIndex}
          vendorRecords={vendorRecords}
          keyPayEmployeeRecords={keyPayEmployeeRecords}
        />
      )}
    </Dialog>
  );
};

// eslint-disable-next-line react/display-name, react/prop-types
const PopupEditing = React.memo(
  ({
    popupComponent: Popup,
    userTabIndex,
    setUserTabIndex,
    vendorRecords,
    keyPayEmployeeRecords,
    selectedEmployeeToRegister,
    setSelectedEmployeeToRegister,
    setRows,
    selectedUserType,
    setSelectedUserType,
    approvalRequired,
    setApprovalRequired,
    selectedApprover,
    setSelectedApprover,
    portalAccess,
    setPortalAccess,
    portalAccessDialog,
    setPortalAccessDialog,
    rows
  }) => (
    <Plugin>
      <Template name="popupEditing">
        <TemplateConnector>
          {(
            { getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
            {
              changeRow,
              changeAddedRow,
              commitChangedRows,
              commitAddedRows,
              stopEditRows,
              cancelAddedRows,
              cancelChangedRows
            }
          ) => {
            const isNew = addedRows.length > 0;
            let editedRow;
            let rowId;
            if (isNew) {
              rowId = 0;
              editedRow = addedRows[rowId];
            } else {
              [rowId] = editingRowIds;
              const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
              editedRow = { ...targetRow, ...rowChanges[rowId] };
            }

            const processValueChange = ({ target: { name, value } }) => {
              const changeArgs = {
                rowId,
                change: createRowChange(editedRow, value, name)
              };
              if (isNew) {
                changeAddedRow(changeArgs);
              } else {
                changeRow(changeArgs);
              }
            };

            const rowIds = isNew ? [0] : editingRowIds;
            const applyChanges = () => {
              //when new user is created
              if (isNew) {
                // open = true;
                // once save button is clicked while adding new user
                let employeePayload = getFormattedEmployeePayload(selectedEmployeeToRegister, editedRow);

                employeePayload = {
                  ...employeePayload,
                  type: selectedUserType.value,
                  portalAccess: portalAccess,
                  approverId: selectedApprover?.id,
                  approvalRequired: approvalRequired
                };

                employeeService
                  .registerEmployee(employeePayload)
                  .then(({ data }) => {
                    let employeeData = [];
                    employeeData.push(data.data);
                    const formattedEmployee = getFormattedEmployeeList(employeeData)[0];

                    setRows([formattedEmployee, ...rows]);

                    commitAddedRows(data.data._id);

                    //set editedRow to hold the popup modal
                    [rowId] = data.data._id;
                    editedRow = formattedEmployee;

                    // set added user in localStorage to use in accounting
                    // eslint-disable-next-line no-undef
                    localStorage.setItem('addedUser', JSON.stringify(formattedEmployee));

                    toast.success('Successfully created new user');
                  })
                  .catch((error) => {
                    toast.error(error?.response?.data?.message || 'Could not create employee');
                  });
                // .finally(() => {
                //   setSelectedEmployeeToRegister();
                // });
              } else {
                stopEditRows({ rowIds });
                commitChangedRows({ rowIds });
                //once save button is clicked while editing user

                //update editedRow when userType is changed
                let updateEditedRow = updateEmployeeType(selectedUserType, editedRow);

                //check if approvalRequired and selectedApprover is changed or not. If changed update ##updateEditedRow
                updateEditedRow = updateEmployeeApprovalDetails(selectedApprover, approvalRequired, updateEditedRow);

                let employeePayload1 = {
                  // eslint-disable-next-line no-undef
                  token: localStorage.getItem('token'),
                  updateUserId: updateEditedRow.id,
                  ...updateEditedRow
                };

                const url1 = Constants.BASE_URL + 'employee/updateEmployee';

                if (employeePayload1.KeyPayEmployeeId) {
                  //if keypay user id
                  const employeePayload2 = getFormattedEmployeePayload(selectedEmployeeToRegister, updateEditedRow);

                  const url2 =
                    Constants.BASE_URL + `keyPayEmployee/updateOneKeyPayEmployee/${employeePayload2.KeyPayEmployeeId}`;

                  axios.all([axios.post(url2, employeePayload2), axios.post(url1, employeePayload1)]).then(
                    axios.spread((data1, data2) => {
                      // output of req. data1 and data2
                      const updatedRow = data2.data.data;
                      const formattedRow = getFormattedEmployeeList(updatedRow)[0];

                      const updatedRows = rows.map((row) => {
                        if (row.id === formattedRow.id) {
                          return formattedRow;
                        }
                        return row;
                      });
                      setRows(updatedRows);

                      return toast.success('successfully updated employee data.');
                    })
                  );
                }

                if (!employeePayload1.KeyPayEmployeeId) {
                  axios
                    .post(url1, employeePayload1)
                    .then((response) => {
                      return toast.success(response.message || 'Successfully updated user');
                    })
                    .catch((error) => {
                      return toast.error(error?.message || 'Could not update employee');
                    })
                    .finally(() => {
                      setSelectedEmployeeToRegister();
                    });
                }
              }
            };

            const cancelChanges = () => {
              if (isNew) {
                cancelAddedRows({ rowIds });
                setSelectedEmployeeToRegister();
                setSelectedUserType({ value: '', label: '' });
                setSelectedApprover({ value: '', label: '', id: '' });
                setApprovalRequired(false);
                setUserTabIndex(0);
              } else {
                stopEditRows({ rowIds });
                cancelChangedRows({ rowIds });
                setSelectedEmployeeToRegister();
                setSelectedUserType({ value: '', label: '' });
                setSelectedApprover({ value: '', label: '', id: '' });
                setApprovalRequired(false);
                setUserTabIndex(0);
              }
            };

            let open = editingRowIds.length > 0 || isNew;

            return (
              <Popup
                open={open}
                row={editedRow}
                onChange={processValueChange}
                onApplyChanges={applyChanges}
                onCancelChanges={cancelChanges}
                userTabIndex={userTabIndex}
                setUserTabIndex={setUserTabIndex}
                vendorRecords={vendorRecords}
                keyPayEmployeeRecords={keyPayEmployeeRecords}
                selectedEmployeeToRegister={selectedEmployeeToRegister}
                setSelectedEmployeeToRegister={setSelectedEmployeeToRegister}
                selectedUserType={selectedUserType}
                setSelectedUserType={setSelectedUserType}
                approvalRequired={approvalRequired}
                setApprovalRequired={setApprovalRequired}
                selectedApprover={selectedApprover}
                setSelectedApprover={setSelectedApprover}
                portalAccessDialog={portalAccessDialog}
                setPortalAccessDialog={setPortalAccessDialog}
                portalAccess={portalAccess}
                setPortalAccess={setPortalAccess}
                setRows={setRows}
                rows={rows}
              />
            );
          }}
        </TemplateConnector>
      </Template>
      <Template name="root">
        <TemplatePlaceholder />
        <TemplatePlaceholder name="popupEditing" />
      </Template>
    </Plugin>
  )
);

const getRowId = (row) => row.id;

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

const ActiveStatusProvider = (props) => {
  return <DataTypeProvider formatterComponent={ActiveStatusFormatter} {...props} />;
};

const ActiveStatusFormatter = ({ value }) => {
  const isActive = value === 'Active';

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      <span
        style={{
          height: '8px',
          width: '8px',
          borderRadius: '50%',
          backgroundColor: isActive ? 'green' : 'red'
        }}
      ></span>
      <span>{value}</span>
    </div>
  );
};

const UserTable = ({ rows, setRows }) => {
  const [userTabIndex, setUserTabIndex] = useState(0);
  const { vendorRecords } = useFetchVendorRecords();
  const { keyPayEmployeeRecords } = useFetchKeyPayEmployeeRecords();
  const [selectedEmployeeToRegister, setSelectedEmployeeToRegister] = useState();

  const [columns] = useState([
    { name: 'DisplayName', title: 'Display Name' },
    { name: 'email', title: 'Email' },
    { name: 'Phone', title: 'Phone' },
    { name: 'userRole', title: 'User Role' },
    {
      name: 'vendorRef',
      title: 'Supplier',
      getCellValue: (row) => (row.vendorRef ? row.vendorRef.name : undefined)
    },
    {
      name: 'itemRef',
      title: 'Item ',
      getCellValue: (row) => (row.itemRef ? row.itemRef.name : undefined)
    },
    {
      name: 'taxType',
      title: 'Tax Type ',
      getCellValue: (row) => (row.taxType ? row.taxType.name : undefined)
    },
    { name: 'jobTitle', title: 'Job Title' },
    { name: 'KeyPayEmployeeId', title: 'External Id' },
    { name: 'hourRate', title: 'Hour Rate' },
    { name: 'activeUsers', title: 'Active Users', getCellValue: (row) => row?.portalAccess.toString() }
  ]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'DisplayName', width: 180 },
    { columnName: 'email', width: 180 },
    { columnName: 'Phone', width: 180 },
    { columnName: 'userRole', width: 180 },
    { columnName: 'vendorRef', width: 180 },
    { columnName: 'itemRef', width: 180 },
    { columnName: 'taxType', width: 180 },
    { columnName: 'jobTitle', width: 180 },
    { columnName: 'KeyPayEmployeeId', width: 180 },
    { columnName: 'hourRate', width: 180 },
    { columnName: 'activeUsers', width: 180 }
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'DisplayName', direction: 'asc' }]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 75]);
  const [searchValue, setSearchState] = useState('');
  const [selection, setSelection] = useState([]);

  const [columnOrder, setColumnOrder] = useState([
    'DisplayName',
    'email',
    'userRole',
    'Phone',
    'vendorRef',
    'itemRef',
    'taxType',
    'jobTitle',
    'KeyPayEmployeeId',
    'hourRate',
    'activeUsers'
  ]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(
    // eslint-disable-next-line no-undef
    JSON.parse(localStorage.getItem('userHiddenColumns')) || ['hourRate', 'KeyPayEmployeeId']
  );

  const [selectedUserType, setSelectedUserType] = useState({ value: '', label: '' });
  const [approvalRequired, setApprovalRequired] = useState(false);
  const [selectedApprover, setSelectedApprover] = useState({ value: '', label: '', id: '' });
  const [portalAccessDialog, setPortalAccessDialog] = useState(false);
  const [portalAccess, setPortalAccess] = useState(false);

  const [activeUsersColumn] = useState(['activeUsers']);

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ];
    }

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }

    //if delete button clicked
    if (deleted) {
      // eslint-disable-next-line no-undef
      if (window.confirm('Are you sure you want to delete this userId?')) {
        changedRows = deleteRows(deleted);

        employeeService
          .deleteEmployee(deleted[0])
          .then(() => {
            toast.success('successfully deleted user');
          })
          .catch((error) => {
            toast.error('could not delete user' || error?.message);
          });
      }
    }
    setRows(changedRows);
  };

  const exporterRef = useRef();
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  //Sort the data based on the current sorting state
  const sortedRows = useMemo(() => {
    const comparer = (a, b) => {
      const { columnName, direction } = sorting[0];
      const aValue = a[columnName];
      const bValue = b[columnName];

      if (aValue === bValue) {
        return 0;
      }

      return direction === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
    };

    return [...rows].sort(comparer);
  }, [rows, sorting]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <div>Total rows selected: {selection.length}</div>
      </div>

      <Paper>
        <Grid rows={sortedRows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <ActiveStatusProvider for={activeUsersColumn} />

          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />

          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <IntegratedSorting />

          <SearchState value={searchValue} onValueChange={setSearchState} />
          <SelectionState selection={selection} onSelectionChange={setSelection} />

          <IntegratedFiltering />
          <IntegratedPaging />

          <IntegratedSelection />
          <DragDropProvider />

          <Table />
          <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
          <TableHeaderRow showSortingControls />

          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <TableSelection showSelectAll />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
          <PopupEditing
            popupComponent={Popup}
            userTabIndex={userTabIndex}
            setUserTabIndex={setUserTabIndex}
            vendorRecords={vendorRecords}
            keyPayEmployeeRecords={keyPayEmployeeRecords}
            selectedEmployeeToRegister={selectedEmployeeToRegister}
            setSelectedEmployeeToRegister={setSelectedEmployeeToRegister}
            setRows={setRows}
            rows={sortedRows}
            selectedUserType={selectedUserType}
            setSelectedUserType={setSelectedUserType}
            approvalRequired={approvalRequired}
            setApprovalRequired={setApprovalRequired}
            selectedApprover={selectedApprover}
            setSelectedApprover={setSelectedApprover}
            portalAccessDialog={portalAccessDialog}
            setPortalAccessDialog={setPortalAccessDialog}
            portalAccess={portalAccess}
            setPortalAccess={setPortalAccess}
          />
          <PagingPanel pageSizes={pageSizes} />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          hiddenColumnNames={hiddenColumnNames}
          selection={selection}
          onSave={onSave}
          getRowId={getRowId}
        />
      </Paper>
    </div>
  );
};

export default UserTable;

UserTable.propTypes = {
  rows: PropTypes.array,
  setRows: PropTypes.func
};
