import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  item: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: '0.5rem 2.5rem',
    cursor: 'pointer',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    '@media only screen and (max-width: 1061px) and (min-width: 500px) ': {
      flex: 0
    }
  },
  top: {
    display: 'flex'
  },
  totalCount: {
    marginRight: '1rem'
  }
}));

const ExpenseStatus = ({ expenseStatusList, filter, setFilter }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {expenseStatusList.map((status, index) => {
        if (status.label === 'All') {
          return null;
        }

        return (
          <div
            key={index}
            className={classes.item}
            onClick={() =>
              setFilter({
                ...filter,
                expensePeriod: filter.expensePeriod,
                startDate: filter.startDate,
                endDate: filter.endDate,
                expenseStatus: status.label
              })
            }
            style={{ backgroundColor: filter.expenseStatus == status.label ? '#f2740d' : '#3f51b5' }}
          >
            <div className={classes.top}>
              <p className={classes.totalCount}>{status.totalCount}</p>
              <p>{status.label}</p>
            </div>
            <div>{status.totalExpenses}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ExpenseStatus;

ExpenseStatus.propTypes = {
  expenseStatusList: PropTypes.array,
  filter: PropTypes.shape({
    expensePeriod: PropTypes.string,
    startDate: PropTypes.null || PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    expenseStatus: PropTypes.string
  }),
  setFilter: PropTypes.func
};
