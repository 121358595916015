import React, { useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Button, Grid, InputBase, InputLabel, makeStyles, Paper, useMediaQuery, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChangeEmailDialog from './ChangeEmailDialog';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    '@media (max-width: 600px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}));

const emailChangesDetails = [
  "Verd db emmployee's email will be changed",
  "KeyPay is not updating the email, manually need to go to QuickBooks and change the email as account email needs to send the email notifications as well",
  'Future calendar event of existing email address will be changed to new email address, whereas past events will be leave as it is.',
  'Qualifications expiry email set for the existing email address in the cloud task will be sent to new email address.',
  'EmailUserHistory will be created to store existing and new email address along with KeyPayId and VerdEmployeeId '
];

const SuperAdmin = ({ row, onCancelChanges }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  const handleEmailDialog = () => {
    setEmailDialogOpen(true);
  };

  // eslint-disable-next-line no-undef
  const adminUserId = localStorage.getItem('userId');
  const developmentAdminUserId =['6454eea703c41e24a9d555dc','64599829bdcb5400115681c1'];
  const productionAdminUserId=["646421044c1a790011e3c33c", "64599829bdcb5400115681c1"];

  // eslint-disable-next-line no-undef
  // if(process.env.NODE_ENV==="production"&productionAdminUserId.includes(adminUserId)||
  // // eslint-disable-next-line no-undef
  // process.env.NODE_ENV!=="production"&developmentAdminUserId.includes(adminUserId)?
  // )

  return (
    <div style={{ height: '800px' }}>

      {
      // eslint-disable-next-line no-undef
      process.env.NODE_ENV==="production"&productionAdminUserId.includes(adminUserId)||
    // eslint-disable-next-line no-undef
    process.env.NODE_ENV!=="production"&developmentAdminUserId.includes(adminUserId) ? (
        <>
          <h2 style={{ borderBottom: 'none', padding: isSmall ? '10px 1rem' : '10px 2rem', overflowX: 'hidden' }}>
            Change Employee Email
          </h2>

          <Grid container spacing={3} style={{ width: '100%', padding: isSmall ? '0 2rem' : '0 3rem' }}>
            <Paper className={classes.paper}>
              <Grid item xs={12} sm={10}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Email
                </InputLabel>
                <BootstrapInput
                  name="email"
                  value={row?.email || ''}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={2} style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                <Button onClick={handleEmailDialog} variant="outlined" color="primary">
                  Change
                </Button>
              </Grid>

              {emailDialogOpen && (
                <ChangeEmailDialog
                  emailDialogOpen={emailDialogOpen}
                  setEmailDialogOpen={setEmailDialogOpen}
                  row={row}
                />
              )}
            </Paper>
          </Grid>

          <div
            style={{
              padding: isSmall ? '10px 1rem' : '10px 2rem',
              marginTop: '2rem'
            }}
          >
            <h4>Following changes will be applied if the email address is changed</h4>

            <ul>
              {emailChangesDetails.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: isSmall ? '10px 1rem' : '10px 2rem',
              marginTop: '2rem'
            }}
          >
            <Button onClick={onCancelChanges} variant="outlined" color="secondary">
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <h4 style={{ padding: isSmall ? '10px 1rem' : '10px 2rem' }}>Only access to super admin</h4>
        </>
      )}
    </div>
  );
};

export default SuperAdmin;

SuperAdmin.propTypes = {
  row: PropTypes.array,
  onCancelChanges: PropTypes.func
};
