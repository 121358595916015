import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';

import expenseService from '../../../services/ExpenseService';
import { getFormattedTableRows } from '../table/helper';

export default function useFetchExpense(filter) {
  const [expenseRecords, setExpenseRecords] = useState([]);
  const [expenseLoading, setExpenseLoading] = useState(false);
  const [expenseError, setExpenseError] = useState(null);

  useEffect(() => {
    const fetchExpenseRecords = async () => {
      try {
        setExpenseLoading(true);
        const fetchExpense = await expenseService.getExpenses();

        const formattedExpenseRecords = getFormattedTableRows(fetchExpense.data.data);

        const filteredRows = formattedExpenseRecords.filter((r) => {
          if (filter.timesheetPeriod === 'All') return true;

          if (
            (filter.startDate === null || new Date(r.ReceiptDate) >= new Date(filter.startDate).getTime()) &&
            new Date(r.ReceiptDate) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }

          return false;
        });

        const searchedRows = searchRows(filteredRows, filter.searchQuery);

        setExpenseRecords(searchedRows);
      } catch (error) {
        setExpenseError(error?.message || 'Could not fetch expense records of given userId');
      } finally {
        setExpenseLoading(false);
      }
    };
    fetchExpenseRecords();
  }, [filter.endDate, filter.searchQuery, filter.startDate, filter.timesheetPeriod]);

  return { expenseRecords, expenseLoading, expenseError, setExpenseRecords };
}

function searchRows(allRows, query) {
  const searchedRows = allRows.filter((r) => {
    return (
      r.CustomerRef?.name.toLowerCase().includes(query) ||
      r.EmployeeRef?.name.toLowerCase().includes(query) ||
      r.status.toLowerCase().includes(query) ||
      r.variationNote.toLowerCase().includes(query) ||
      r.Description.toLowerCase().includes(query) ||
      r.ClassRef?.name.toLowerCase().includes(query) ||
      r.VendorRef?.name.toLowerCase().includes(query) ||
      r.ItemRef?.name.toLowerCase().includes(query) ||
      r.ItemRef?.name.toLowerCase().includes(query)
    );
  });

  return searchedRows;
}
