import axios from 'axios';
import { BASE_URL } from '../components/Constant';

function getUserApprover(employeeId) {
  const endpoint = BASE_URL + `employee/getEmployeeApprover/${employeeId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

function getEmployeeDetails() {
  // eslint-disable-next-line no-undef
  const employeeId = localStorage.getItem('userId');
  const endpoint = BASE_URL + `employee/getOneEmployee/${employeeId}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

function updateEmployeeMarkupPercentage(markupData) {
  // eslint-disable-next-line no-undef
  const employeeId = localStorage.getItem('userId');
  const endpoint = BASE_URL + `employee/updateEmployeeMarkupPercentage/${employeeId}`;

  return axios.put(
    endpoint,
    { data: markupData },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

function changeEmployeePassword(passwordDetails) {
  // eslint-disable-next-line no-undef
  const employeeId = localStorage.getItem('userId');

  const endpoint = BASE_URL + `employee/changeEmployeePassword/${employeeId}`;

  return axios.put(
    endpoint,
    { passwordDetails: passwordDetails },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//update employee profile
function updateEmployeeProfile(employeeDetails) {
  const endpoint = BASE_URL + 'employee/updateEmployeeProfile';

  return axios.put(
    endpoint,
    { employeeDetails: employeeDetails },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//get all employees
function getEmployees() {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/list';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get all employees
function registerEmployee(employeePayload) {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/register';

  return axios.post(
    endpoint,
    { employeePayload: employeePayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//send login details to employee
function sendLoginDetailsToUser(employeePayload) {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/sendLoginDetailsToUser';

  return axios.post(endpoint, employeePayload, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//remove login details of employees
function removeLoginDetailsOfUser(employeePayload) {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/removeLoginDetailsOfUser';

  return axios.put(
    endpoint,
    { employeePayload: employeePayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//delete employee
function deleteEmployee(employeeId) {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + `employee/deleteEmployee/${employeeId}`;

  return axios.delete(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get all employees
function getEmployeeApproverRecord() {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/getEmployeeApproverRecord';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

function getAdmins() {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/getAdmins';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

function getActiveEmployees() {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/getActiveEmployees';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update email
function updateEmployeeEmailByAdmin(employeePayload) {
  // eslint-disable-next-line no-undef
  const endpoint = BASE_URL + 'employee/updateEmployeeEmailByAdmin';

  return axios.put(
    endpoint,
    { employeePayload: employeePayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const employeeService = {
  getUserApprover,
  getEmployeeDetails,
  updateEmployeeMarkupPercentage,
  changeEmployeePassword,
  updateEmployeeProfile,
  getEmployees,
  registerEmployee,
  sendLoginDetailsToUser,
  removeLoginDetailsOfUser,
  deleteEmployee,
  getEmployeeApproverRecord,
  getAdmins,
  getActiveEmployees,
  updateEmployeeEmailByAdmin
};

export default employeeService;
